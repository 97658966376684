import {
  IonBadge,
  IonContent,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { hubIconMapper } from "shared/components/icons/hub-icons-mapper";
import { AppRouteConfigView } from "shared/mappers/database/app-route/app-route-config/app-route-config";
import NewAppButton from "admin/src/ui/components/common/newform/NewAppButton";
import { SocietyLogo } from "hub/src/components/common/SocietyLogo";
import ChevronBackIcon from "hub/src/components/icons/ChevronBackIcon";
import LogOutOutlineIcon from "hub/src/components/icons/LogOutOutlineIcon";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { useHistory, useLocation } from "react-router";
import { mapPathParamsToUrl } from "hub/src/utils/mapPathParamsToUrl";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import AdminImpersonateInfo from "hub/src/components/admin/AdminImpersonateInfo";
import MenuItemDisclosure from "hub/src/components/sideMenu/MenuItemDisclosure";
import { snakeCase } from "lodash";
import { app_route_config_nav } from "@prisma/client";
import NewAppLoadingButton from "admin/src/ui/components/common/newform/NewAppLoadingButton";
const MainMenu = () => {
  const session = useHubSessionContext();
  const location = useLocation();
  const history = useHistory();
  const device = useDeviceInfo();

  // const { data: statsResp } = useApiQuery(
  //   GetClientDashboardStats,
  //   {
  //     societyId: getSocietyId().toString(),
  //     profileId: session.profileId!.toString(),
  //   },
  //   {},
  //   {},
  //   {
  //     staleTime: -1,
  //     enabled: !!session.profileId!,
  //   },
  // );

  // Had to add an extra check here.
  // There's a weird bug where the response is not coming back as an array.
  // TODO: Need to look into this more. Not able to recreate after clearing all caches.
  // const newReviewStats = (Array.isArray(statsResp?.data?.body) &&
  //   // For review, we only care about New review counts.
  //   statsResp?.data?.body?.find((stat) => stat.statusName === "New")) as
  //   | any
  //   | undefined;

  const getMenuItemBadge = (routeComponentName: string) => {
    // Map badge data based on the component's name from route.
    // Make sure to use component names that match the names stored in the database.
    // Similar to how we handle UserHub Routes.

    const badgeData: Record<string, number | undefined> = {
      //   chatsPage: unReadChatCountQuery.data,
      //submissionWorkflowReviewsPage: newReviewStats?.statusCount ?? 0,
      // submissionAssignmentPage: session.profile?.submissionAssignments?.filter(
      //   (submissionAssignment: any) => {
      //     return submissionAssignment.isActive;
      //   },
      // ).length,
      submissionAssignmentPage: 0,
    };

    const badgeValue = badgeData[routeComponentName];

    // Display a badge if there's a value.
    if (badgeValue) {
      return <IonBadge className="ml-4">{badgeValue}</IonBadge>;
    }

    return null;
  };

  const MenuItemLink = (route: HubRouteConfig) => {
    if (!route.navType?.includes(app_route_config_nav.userhub_nav)) {
      return null;
    }
    const IconComponent = route.iconName
      ? hubIconMapper[route.iconName]
      : undefined;

    return (
      <>
        <button
          data-testid={`main-menu-${
            route.parentAppRouteConfig
              ? snakeCase(route.parentAppRouteConfig.label) + "-"
              : ""
          }${snakeCase(route.label)}`}
          onClick={() => {
            if (route.externalRoute) {
              route.openInNewTab
                ? window.open(route.url)
                : (window.location.href = route.url!);
            } else {
              history.push(mapPathParamsToUrl(route.url!, route.routeParams!));
            }
          }}
          className={`sidebar-menu-selector flex flex-row w-full rounded-lg items-center text-start group ${
            route.label === "Communication" && "sm:hidden"
          }`}
        >
          {IconComponent && (
            <IconComponent className="sidebar-menu-svg ion-like-icon" />
          )}
          <div
            className={`sidebar-menu-title ${
              route.label === "Communication" && "sm:hidden"
            }`}
          >
            {route.childrenAppRouteConfig?.length ? "Main" : route.label}
          </div>
          {getMenuItemBadge(route.componentName!)}
        </button>
      </>
    );
  };

  const renderMenuItems = (routeConfigs: AppRouteConfigView[]) => {
    return routeConfigs?.map((route) => {
      // When using the native app, only load the routes
      // for the native app
      if (device.isNativeApp) {
        return null; // Skip rendering this route
      }

      const IconComponent = route.iconName
        ? hubIconMapper[route.iconName]
        : undefined;

      return route.childrenAppRouteConfig &&
        route.childrenAppRouteConfig.length ? (
        <MenuItemDisclosure
          key={`route-nav-${route.label}`}
          label={route.label}
          initialIsOpen={false}
          buttonClassName="sidebar-menu-selector flex flex-row w-full rounded-lg items-center text-start group"
          chevronIconClassname="w-2 h-2 mr-1"
          IconComponent={IconComponent}
        >
          <MenuItemLink {...(route as HubRouteConfig)} />
          {renderMenuItems(route.childrenAppRouteConfig)}
        </MenuItemDisclosure>
      ) : (
        <MenuItemLink
          key={`route-nav-${route.label}`}
          {...(route as HubRouteConfig)}
        />
      );
    });
  };

  return (
    <IonMenu
      type="overlay"
      contentId="main-content"
      disabled={!session.societyUserId}
    >
      <IonContent className="border-r border-neutral-mid-100 ios-side-menu-wrapper">
        <div className="flex flex-col h-full justify-between">
          <div>
            <AdminImpersonateInfo />
            <IonList inset={true}>
              <div className="flex ml-2 items-center">
                <SocietyLogo className="h-9.5 w-9.5 mb-2" />
                <h2 className="mb-1.5 ml-2 text-title">
                  {session.society?.abbreviation}
                </h2>
              </div>
              <div className="flex flex-col gap-0.5">
                {renderMenuItems(session.society!.routes!)}
              </div>
            </IonList>
          </div>
          <div key="special-action-bank" className="menu-action-bank px-1">
            <div className="menu-section-border" />
            {location.pathname.includes("conference") && (
              <select
                className="w-full  mb-1"
                onChange={(e) => {
                  session.setTimezone(e.target.value);
                }}
              >
                <option value="system">Local Time</option>
                <option value="conference">Conference Venue Time</option>
              </select>
            )}
            <NewAppLoadingButton
              className="menu-action-bank-button !gap-x-0"
              onClick={async () => await session.logout()}
            >
              <LogOutOutlineIcon className="menu-action-bank-icon ion-like-icon" />
              Sign Out
            </NewAppLoadingButton>
          </div>
          <div key="special-action-close" className="fixed bottom-2 right-2">
            <IonMenuToggle>
              <NewAppButton className="menu-button-close h-3 w-3">
                <ChevronBackIcon className="ion-like-icon" />
              </NewAppButton>
            </IonMenuToggle>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default MainMenu;
