import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://ionic.io/ionicons type in input ribbon -> in outline section
const RibbonOutline = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames =
    "icon icon-ionicons icon-ionicons-ribbon h-2.75 w-2.75";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 512 512"
      {...props}
    >
      <circle
        cx="256"
        cy="160"
        r="128"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        d="M143.65 227.82L48 400l86.86-.42a16 16 0 0113.82 7.8L192 480l88.33-194.32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        d="M366.54 224L464 400l-86.86-.42a16 16 0 00-13.82 7.8L320 480l-64-140.8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <circle
        cx="256"
        cy="160"
        r="64"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </svg>
  );
};

export default RibbonOutline;
