import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://ionic.io/ionicons type in input stacked -> in outline section
const FileTrayStackedOutline = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames =
    "icon icon-ionicons icon-ionicons-file-tray-stacked h-2.75 w-2.75";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M48 336v96a48.14 48.14 0 0048 48h320a48.14 48.14 0 0048-48v-96"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M48 336h144M320 336h144M192 336a64 64 0 00128 0"
      />
      <path
        d="M384 32H128c-26 0-43 14-48 40L48 192v96a48.14 48.14 0 0048 48h320a48.14 48.14 0 0048-48v-96L432 72c-5-27-23-40-48-40z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M48 192h144M320 192h144M192 192a64 64 0 00128 0"
      />
    </svg>
  );
};

export default FileTrayStackedOutline;
