/* eslint-disable react-hooks/rules-of-hooks */
import { useCustomRouter } from "@parthenon-management/pillar-admin/src/ui/context/CustomRouterContext";
import { Prisma } from "@prisma/client";
import { useQuery } from "@tanstack/react-query";
import { WorkflowInstancePayloadBase } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { WorkflowActionDefinition } from "admin/src/server/bll/workflow/WorkflowDefinition";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { SurveyPreviewProps } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import { submissionTemplateHelper } from "admin/src/utils/submissionTemplateHelper";
import { renderTemplateUtil } from "admin/src/utils/template/renderTemplateUtil";
import { queryClient } from "hub/src/api/client/query-client";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import { useMemo, useState } from "react";
import { getSocietyFormIdContract } from "shared/api/contracts/society/societyId/forms/formId";
import { postSocietyProfileConferenceRegistrationAutosaveContract } from "shared/api/contracts/society/societyId/profiles/profileId/conference/conferenceId/registration/conferenceRegistrationId/autosave";
import { postSocietyProfileConferenceRegistrationWorkflowActionEndpointContract } from "shared/api/contracts/society/societyId/profiles/profileId/conference/conferenceId/registration/conferenceRegistrationId/workflowActionEndpoint";
import { getSocietyProfileConferenceRegistrationUpsertContract } from "shared/api/contracts/society/societyId/profiles/profileId/conference/conferenceId/registration/upsert";
import { getSocietyProfileDefinitionSubmissionInstanceIdContract } from "shared/api/contracts/society/societyId/profiles/profileId/submission/submissionDefinitionId/instance/submissionInstanceId";
import { postSocietyProfileDefinitionSubmissionInstanceAutosaveContract } from "shared/api/contracts/society/societyId/profiles/profileId/submission/submissionDefinitionId/instance/submissionInstanceId/autosave";
import { postSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpointContract } from "shared/api/contracts/society/societyId/profiles/profileId/submission/submissionDefinitionId/instance/submissionInstanceId/workflowActionEndpoint";
import {
  getSocietyWorkflowInstanceContract,
  postSocietyWorkflowInstanceContract,
} from "shared/api/contracts/society/societyId/workflow/workflowId/instance";
import { ConferenceRegistrationView } from "shared/mappers/database/conference/conference-registration";
import { SubmissionInstanceView } from "shared/mappers/database/submission/instance/submission-instance";
import { SurveyModel } from "survey-core";
const defaultPossibleActionEndpoint = ({
  possibleActions = [],
}: {
  possibleActions?: WorkflowActionDefinition[];
}) => {
  const submit = possibleActions.find((pa) => pa.endpoint == "submit");
  return submit?.endpoint;
};

export const useFormPageValues = (routeConfig: HubRouteConfig<any>) => {
  const session = useHubSessionContext();
  const profileId = session.profileId!;
  const formPageValues: Partial<SurveyPreviewProps> = {};
  const router = useCustomRouter();
  const [parentWorkflowInstanceId, setParentWorkflowInstanceId] = useState();

  let submissionResponse: SubmissionInstanceView | undefined;
  let conferenceResponse: ConferenceRegistrationView | undefined;

  const { routeParams } = routeConfig as HubRouteConfig<any>;

  const additionalOnCompleteActions = (sender: SurveyModel) => {
    if (profileId && !sender.data.profileId) {
      sender.setValue("profileId", profileId);
    }
    if (formPageValues.formDesign?.id) {
      sender.setValue("formId", formPageValues.formDesign?.id);
    }
    sender.completedHtml =
      "Please wait processing... <br/><div class='inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] w-4 h-4' style='border-color: blue; border-right-color: transparent;'></div>";
  };

  if (routeParams?.submissionDefinitionId && routeParams.submissionInstanceId) {
    const { data: submissionData } = useQuery(
      ["submission", routeParams.submissionInstanceId],
      async () =>
        await newApiRequest(
          getSocietyProfileDefinitionSubmissionInstanceIdContract,
          {
            params: {
              societyId: session.societyId!,
              profileId,
              submissionDefinitionId: Number(
                routeParams?.submissionDefinitionId,
              ),
              submissionInstanceId: Number(routeParams?.submissionInstanceId),
            },
          },
        ),
    );
    formPageValues.overrideCompleteButtonText = routeParams?.title
      ? routeParams.title
      : "Complete";

    formPageValues.onCompleteCallback = async (sender: SurveyModel) => {
      additionalOnCompleteActions(sender);

      const workflowInstanceStopped = await newApiRequest(
        postSocietyProfileDefinitionSubmissionInstanceWorkflowActionEndpointContract,
        {
          params: {
            societyId: session.societyId!,
            profileId,
            submissionDefinitionId: routeParams.submissionDefinitionId!,
            submissionInstanceId: routeParams.submissionInstanceId!,
            workflowActionEndpoint:
              routeParams.workflowActionEndpoint ??
              defaultPossibleActionEndpoint({
                possibleActions:
                  submissionData?.workflowInstance?.possibleActions,
              }) ??
              "",
          },
          body: { payload: sender.data },
        },
      );

      await queryClient.invalidateQueries({ queryKey: ["submissions"] });

      if (workflowInstanceStopped?.userhubRedirect) {
        sender.completedHtml = "Completed! Redirecting...";
        router.push(
          routeParams?.redirectUrl
            ? routeParams.redirectUrl
            : workflowInstanceStopped.userhubRedirect,
        );
      }

      return workflowInstanceStopped;
    };

    formPageValues.autoSaveChangesCallback = async (
      submissionFormData: Prisma.JsonValue,
    ) => {
      await autoSaveApiRequest({
        societyId: session.societyId!,
        profileId,
        submissionDefinitionId: Number(routeParams?.submissionDefinitionId),
        submissionInstanceId: Number(routeParams?.submissionInstanceId),
        formData: submissionFormData as WorkflowInstancePayloadBase,
      });
      await queryClient.invalidateQueries({ queryKey: ["submissions"] });
    };

    formPageValues.overrideDescription = submissionTemplateHelper({
      submissionInstance: submissionData!,
      submissionDefinition: submissionData?.submissionDefinition,
      type: "additionalInfoTemplateDetailed",
    });
    formPageValues.formDesign = {
      ...submissionData?.form?.formDesign,
      submissionDefinitionId: routeParams?.submissionDefinitionId,
      submissionInstanceId: routeParams?.submissionInstanceId,
    };

    formPageValues.formData = submissionData?.workflowInstance?.payload;
    submissionResponse = submissionData;
  }

  if (routeParams?.conferenceId) {
    const { data: conferenceData } = useQuery(
      ["conferenceId", routeParams.conferenceId],
      async () =>
        await newApiRequest(
          getSocietyProfileConferenceRegistrationUpsertContract,
          {
            params: {
              societyId: session.societyId!,
              profileId,
              conferenceId: Number(routeParams?.conferenceId),
            },
          },
        ),
    );

    formPageValues.onCompleteCallback = async (sender: SurveyModel) => {
      additionalOnCompleteActions(sender);

      const workflowInstanceStopped = await newApiRequest(
        postSocietyProfileConferenceRegistrationWorkflowActionEndpointContract,
        {
          params: {
            societyId: session.societyId!,
            profileId,
            conferenceId: routeParams.conferenceId!,
            conferenceRegistrationId: conferenceData?.conferenceRegistrationId!,
            workflowActionEndpoint:
              routeParams.workflowActionEndpoint ??
              defaultPossibleActionEndpoint({
                possibleActions:
                  conferenceData?.workflowInstance?.possibleActions,
              }) ??
              "",
          },
          body: { payload: sender.data },
        },
      );

      await queryClient.invalidateQueries({ queryKey: ["conferences"] });
      await session.refreshSession();

      if (workflowInstanceStopped?.userhubRedirect) {
        sender.completedHtml = routeParams?.title
          ? routeParams.title
          : "Completed! Redirecting...";
        router.push(
          routeParams?.redirectUrl
            ? routeParams.redirectUrl
            : workflowInstanceStopped.userhubRedirect,
        );
      }

      return workflowInstanceStopped;
    };

    formPageValues.formDesign = {
      ...conferenceData?.conference?.registrationForm?.formDesign,
      conferenceId: Number(routeParams?.conferenceId),
      conferenceRegistrationId: conferenceData?.conferenceRegistrationId,
    };
    formPageValues.autoSaveChangesCallback = async (
      conferenceFormData: Prisma.JsonValue,
    ) => {
      await autoSaveApiRequest({
        societyId: session.societyId!,
        profileId,
        conferenceId: Number(routeParams?.conferenceId),
        conferenceRegistrationId: conferenceData?.conferenceRegistrationId!,
        formData: conferenceFormData as WorkflowInstancePayloadBase,
      });
      await queryClient.invalidateQueries({ queryKey: ["conferences"] });
    };
    formPageValues.formData = conferenceData?.workflowInstance?.payload;
    conferenceResponse = conferenceData;
  }

  if (routeParams?.formId) {
    const { data: formResponse } = useQuery(
      ["formId", Number(routeParams?.formId)],
      async () =>
        await newApiRequest(getSocietyFormIdContract, {
          params: {
            societyId: session.societyId!,
            formId: Number(routeParams?.formId!),
          },
        }),
    );

    const { data: workflowInstance } = useQuery(
      ["workflowInstance", routeParams.workflowInstanceId],
      async () =>
        await newApiRequest(getSocietyWorkflowInstanceContract, {
          params: {
            societyId: session.societyId!,
            workflowId: Number(routeParams.workflowId),
            workflowInstanceId: Number(routeParams.workflowInstanceId),
          },
        }),
      {
        enabled: Boolean(
          routeParams.workflowId && routeParams.workflowInstanceId,
        ),
      },
    );

    if (routeParams?.parentWorkflowInstanceId && !parentWorkflowInstanceId) {
      setParentWorkflowInstanceId(routeParams?.parentWorkflowInstanceId);
    }

    // Set formPageValues based on the queries' results

    formPageValues.formDesign = formResponse?.formDesign;
    formPageValues.formData = routeParams?.workflowInstanceId
      ? workflowInstance?.payload
      : session.profile?.additionalInformation;

    formPageValues.onCompleteCallback = async (sender: SurveyModel) => {
      additionalOnCompleteActions(sender);

      const workflowInstanceStopped = await newApiRequest(
        postSocietyWorkflowInstanceContract,
        {
          params: {
            societyId: session.society!.societyId,

            workflowId:
              formResponse?.formDesign?.workflowId ?? routeParams.workflowId,
          },
          body: {
            payload: sender.data,
            workflowInstanceId: routeParams.workflowInstanceId,
            parentWorkflowInstanceId,
          },
        },
      );

      if (session.profileId) {
        const queryParams = new URLSearchParams(location.search);
        const invoiceId = queryParams.get("invoiceId");
        const targetUrl = invoiceId ? `/payment/${invoiceId}` : "/overview";
        await session.refreshSession();
        if (workflowInstanceStopped.userhubRedirect) {
          router.push(targetUrl);
        }
      } else {
        await session.refreshSession();
        router.push(routeParams.userhubRedirect ?? "/overview");
      }

      return workflowInstanceStopped;
    };

    if (session.profileId) {
      const queryParams = new URLSearchParams(location.search);
      const invoiceId = queryParams.get("invoiceId");
      const targetUrl = invoiceId ? `/payment/${invoiceId}` : "/overview";
      formPageValues.afterWorkflowInstanceStopped = async (props: {
        workflowInstanceStopped: { userhubRedirect: string };
      }) => {
        await session.refreshSession();
        if (props.workflowInstanceStopped.userhubRedirect) {
          router.push(targetUrl);
        }
      };
    } else {
      formPageValues.formData = [];
      formPageValues.afterWorkflowInstanceStopped = async () => {
        await session.refreshSession();
        router.push("/overview");
      };
    }
  }

  if (routeParams?.overrideTitleTemplate) {
    formPageValues.overrideTitle = renderTemplateUtil({
      template: routeParams.overrideTitleTemplate,
      templateData: {
        profile: session.profile,
        submissionInstance: submissionResponse,
        submissionDefinition: submissionResponse?.submissionDefinition,
        conferenceRegistration: conferenceResponse,
      },
    });
  } else if (submissionResponse) {
    formPageValues.overrideTitle = submissionTemplateHelper({
      submissionInstance: submissionResponse,
      submissionDefinition: submissionResponse.submissionDefinition,
      type: "titleTemplate",
    });
  }

  if (routeParams?.overrideDescriptionTemplate) {
    formPageValues.overrideDescription = renderTemplateUtil({
      template: routeParams.overrideDescriptionTemplate,
      templateData: {
        profile: session.profile,
        submissionInstance: submissionResponse,
        submissionDefinition: submissionResponse?.submissionDefinition,
        conferenceRegistration: conferenceResponse,
      },
    });
  } else if (submissionResponse) {
    formPageValues.overrideDescription = submissionTemplateHelper({
      submissionInstance: submissionResponse,
      submissionDefinition: submissionResponse.submissionDefinition,
      type: "additionalInfoTemplateDetailed",
    });
  }

  useMemo(() => {
    return formPageValues;
  }, [formPageValues]);
  return formPageValues;
};

const autoSaveApiRequest = async ({
  societyId,
  profileId,
  submissionDefinitionId,
  submissionInstanceId,
  conferenceId,
  conferenceRegistrationId,
  formData,
}: {
  societyId: number;
  profileId: number;
  submissionDefinitionId?: number;
  submissionInstanceId?: number;
  conferenceId?: number;
  conferenceRegistrationId?: number;
  formData: WorkflowInstancePayloadBase;
}) => {
  if (
    submissionDefinitionId !== undefined &&
    submissionInstanceId !== undefined
  ) {
    await newApiRequest(
      postSocietyProfileDefinitionSubmissionInstanceAutosaveContract,
      {
        params: {
          societyId: societyId,
          profileId: profileId,
          submissionDefinitionId: Number(submissionDefinitionId),
          submissionInstanceId: Number(submissionInstanceId),
        },
        body: { payload: formData },
      },
    );
  } else if (
    conferenceId !== undefined &&
    conferenceRegistrationId !== undefined
  ) {
    await newApiRequest(
      postSocietyProfileConferenceRegistrationAutosaveContract,
      {
        params: {
          societyId: societyId,
          profileId: profileId,
          conferenceId: Number(conferenceId),
          conferenceRegistrationId: Number(conferenceRegistrationId),
        },
        body: { payload: formData },
      },
    );
  }
};
