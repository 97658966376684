import {
  WorkflowInstancePayloadBase,
  WorkflowInstanceStopped,
} from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { BaseApiContract } from "admin/src/ui/api-callouts/utils";
import { HttpMethod } from "admin/src/ui/types/common/http";
import {
  SocietyWorkflowBodyInput,
  SocietyWorkflowInstanceParamsInput,
  SocietyWorkflowParamsInput,
} from "shared/api/types/society/[societyId]/workflow/[workflowId]/instance/index";
import { WorkflowInstanceView } from "shared/mappers/database/workflow/workflow-instance";

export const getSocietyWorkflowInstanceContract: BaseApiContract<
  SocietyWorkflowInstanceParamsInput,
  undefined,
  WorkflowInstancePayloadBase<WorkflowInstanceView>
> = {
  method: HttpMethod.GET,
  apiRoute:
    "/api/v1/society/[societyId]/workflow/[workflowId]/instance/[workflowInstanceId]",
  paramsType: {} as SocietyWorkflowInstanceParamsInput,
  responseType: {} as WorkflowInstancePayloadBase<WorkflowInstanceView>,
};

export const postSocietyWorkflowInstanceContract: BaseApiContract<
  SocietyWorkflowParamsInput,
  SocietyWorkflowBodyInput,
  WorkflowInstanceStopped
> = {
  method: HttpMethod.POST,
  apiRoute: "/api/v1/society/[societyId]/workflow/[workflowId]/instance",
  paramsType: {} as SocietyWorkflowParamsInput,
  bodyType: {} as SocietyWorkflowBodyInput,
  responseType: {} as WorkflowInstanceStopped,
};
