import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://ionic.io/ionicons type in easel -> in outline section
const EaselOutline = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames =
    "icon icon-ionicons icon-ionicons-easel h-2.75 w-2.75";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 512 512"
      {...props}
    >
      <rect
        x="48"
        y="80"
        width="416"
        height="272"
        rx="32"
        ry="32"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M256 416v-64M256 80V48M400 464l-32-112M112 464l32-112"
      />
    </svg>
  );
};

export default EaselOutline;
